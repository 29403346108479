export const CONTAINER_BREAK_TYPES = {
  AFTER: 'after',
  BEFORE: 'before',
  BOTH: 'both',
} as const;
export type ContainerBreak = (typeof CONTAINER_BREAK_TYPES)[keyof typeof CONTAINER_BREAK_TYPES];

export const ARROW_TYPES = {
  NEXT: 'next',
  PREV: 'prev',
} as const;

export type ArrowType = (typeof ARROW_TYPES)[keyof typeof ARROW_TYPES];

export const ARROW_POSITIONS = {
  CENTER: 'CENTER',
  TOP: 'TOP',
} as const;

export type ArrowPosition = (typeof ARROW_POSITIONS)[keyof typeof ARROW_POSITIONS];
