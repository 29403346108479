import Typography from 'components/Typography/Typography';
import { ReactNode } from 'react';
import { FontTag, FontType, FontWeight, Spacer } from '../../constants/styling';
import { StyledTitle } from './HeaderSimple.styled';

interface HeaderSimpleProps {
  className?: string;
  marginBottom?: Spacer;
  subtitle?: string;
  subtitleFontWeight?: FontWeight;
  subtitleTag?: FontTag;
  subtitleType?: FontType;
  title?: ReactNode;
  titleButton?: ReactNode;
  titleColor?: string;
  titleFontWeight?: FontWeight;
  titleTag?: FontTag;
  titleType?: FontType;
}

const HeaderSimple = ({
  className,
  marginBottom = 50,
  subtitle,
  subtitleFontWeight = 'normal',
  subtitleTag = 'h2',
  subtitleType = 'h3',
  title,
  titleButton,
  titleColor,
  titleFontWeight = 'bold',
  titleTag = 'h1',
  titleType = 'h2',
}: HeaderSimpleProps) => {
  if (!(title || subtitle)) {
    return null;
  }

  return (
    <div className={className}>
      {title && (
        <div className="flex flex-col gap-2 lg:flex-row lg:items-end">
          <StyledTitle
            fontWeight={titleFontWeight}
            marginBottom={marginBottom}
            tag={titleTag}
            type={titleType}
            titleColor={titleColor}
          >
            {title}
          </StyledTitle>
          {titleButton}
        </div>
      )}

      {subtitle && (
        <Typography fontWeight={subtitleFontWeight} tag={subtitleTag} type={subtitleType}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default HeaderSimple;
