export const COLORS = [
  'black',
  'white',
  'primary-100',
  'primary-200',
  'primary-300',
  'primary-300a',
  'primary-transparent-100',
  'primary-transparent-100.1',
  'primary-400',
  'secondary-200',
  'secondary-300',
  'secondary-300a',
  'secondary-transparent-300',
  'secondary-400',
  'love-100',
  'love-100a',
  'love-200',
  'love-300',
  'love-400',
  'ocean-100',
  'ocean-100a',
  'ocean-transparent-100',
  'ocean-200',
  'ocean-300',
  'ocean-400',
  'ocean-500',
  'pearl-200',
  'pearl-300',
  'pearl-400',
  'pearl-500',
  'shadow-100',
  'shadow-300',
  'whisper-100',
  'whisper-transparent-100',
  'whisper-200',
  'whisper-200a',
  'whisper-transparent-200',
  'whisper-300',
  'whisper-400',
  'whisper-500',
  'alert-warning-100',
  'alert-warning-200',
  'alert-danger-100',
  'alert-danger-200',
  'ghost-800',
  'gold',
  '',
] as const;

export default COLORS;
