import COLORS from 'constants/colors';
import { Spacer } from 'constants/styling';
import { color } from 'theme';
import { Color } from 'types/Color';

/**
 *
 * @param colorProp Any string to set the color, could be a value from the COLORS object
 * @returns color black when colorProp is undefined or null, return
 * @returns predefined color value if colorProp exists in the COLORS object
 * @returns given colorProp value if COLORS object does not include in COLORS object
 */
export const getColor = (colorProp: string) => (COLORS.includes(colorProp as Color) ? color(colorProp) : colorProp);

/**
 *
 * @param spacer A value from the SPACERS object
 * @returns null is given spacer is not a number
 * @returns converted number that could be used for pixel definitions
 */

export const convertSpacerRemToPixels = (spacer: Spacer) => {
  if (typeof spacer === 'number') {
    return spacer / 6.25;
  }

  return null;
};
