import Typography from 'components/Typography/Typography';
import styled from 'styled-components';
import { getColor } from 'utils/styleUtil';

interface StyledTitleProps {
  titleColor?: string;
}

export const StyledTitle = styled(Typography)<StyledTitleProps>`
  color: ${({ titleColor }) => titleColor && getColor(titleColor)};
`;
