import { SlidesObject } from 'components/Slider/SliderEmbla/SliderEmbla.styled';
import config from '../config';

const { breakpoints } = config;

export const getAmountOfSlides = (windowSize: number, slidesObject: SlidesObject) => {
  if (windowSize > breakpoints.desktop_xxl) {
    return slidesObject?.xxl || 5;
  }
  if (windowSize > breakpoints.desktop) {
    return slidesObject?.xl || 5;
  }
  if (windowSize > breakpoints.tablet) {
    return slidesObject?.lg || 4;
  }
  if (windowSize > breakpoints.mobile) {
    return slidesObject?.md || 3;
  }
  if (windowSize > breakpoints.small) {
    return slidesObject?.sm || 1.5;
  }
  return slidesObject?.xs || 1.5;
};

export const clampSlidesObject = (slidesToShow: SlidesObject, maxAvailableSlides: number) => {
  if (!Object.keys(slidesToShow)?.length) {
    return null;
  }

  const clampedSlidesToShowEntries = Object.entries(slidesToShow)?.map(([breakpoint, value]) => {
    if (value === 'auto') return [breakpoint, value];
    const clampedValue = Math.min(value, maxAvailableSlides);
    return [breakpoint, clampedValue];
  });

  const clampedSlidesToShow = Object.fromEntries(clampedSlidesToShowEntries);

  return clampedSlidesToShow;
};

export const floorSlidesObject = (slidesToShow: SlidesObject) => {
  if (!Object.keys(slidesToShow)?.length) {
    return null;
  }

  const flooredSlidesToShowEntries = Object.entries(slidesToShow)?.map(([breakpoint, value]) => {
    if (value === 'auto') return [breakpoint, value];
    const flooredValue = Math.floor(value);
    return [breakpoint, flooredValue];
  });

  const flooredSlidesToShow = Object.fromEntries(flooredSlidesToShowEntries);

  return flooredSlidesToShow;
};
