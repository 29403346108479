import { ARROW_TYPES, ArrowType } from 'constants/generic';
import { useIntl } from 'react-intl';
import { IconButton } from 'ui/Button/Button';
import { IconLookup } from 'utils/fontAwesome';

export interface StyledCarouselProps {
  disabled?: boolean;
}

interface CarouselArrowProps extends StyledCarouselProps {
  className?: string;
  id?: string;
  onClick?: () => void;
  type?: ArrowType;
}

const CarouselArrow = ({ className, disabled, id, onClick, type = ARROW_TYPES.NEXT }: CarouselArrowProps) => {
  const { formatMessage } = useIntl();

  const icon: IconLookup = {
    name: ARROW_TYPES.NEXT === type ? 'angle-right' : 'angle-left',
    styling: 'far',
  };

  return (
    <IconButton
      id={id}
      aria-label={formatMessage({ id: 'carousel_arrow_label' })}
      disabled={disabled}
      className={className}
      onClick={onClick}
      icon={icon}
    />
  );
};

export default CarouselArrow;
